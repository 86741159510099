$("#move-one").mousemove(function(e) {
	parallaxIt(e, ".circle-small-slide", -10);
	parallaxIt(e, ".circle-years", -16);
	parallaxIt(e, ".slide-imag", -20);
	parallaxIt(e, ".circle-deform-slide", -20);
	parallaxIt(e, ".circle-deform-slide", -20);
	parallaxIt(e, ".circle-deform-slide", -10);
});


function parallaxIt(e, target, movement) {
	var $this = $("#move-one");

	var relX = e.pageX - $this.offset().left;
	var relY = e.pageY - $this.offset().top;

	TweenMax.to(target, 1, {
		x: (relX - $this.width() / 2) / $this.width() * movement,
		y: (relY - $this.height() / 2) / $this.height() * movement
	});
}

/**********************************FORM VALIDATION*******************************/
function validation() {
	var name = document.forms["RegForm"]["Name"].value;
	var email = document.forms["RegForm"]["EMail"].value;
	var business = document.forms["RegForm"]["Business"].value;
	var country = document.forms["RegForm"]["Country"].value;
	var occ = document.forms["RegForm"]["Occupation"].value;

	var aviso = document.forms["RegForm"]["Aviso"].checked;

	var error_message = document.getElementById("error_message");
	var text;


	if ((name == "") && (email == "") && (business == "")) {
		text = "Llene los campos del formulario"
		error_message.innerHTML = text;
		return false;
	} else
	if (name == "") {
		text = "Por favor inserte su nombre"
		error_message.innerHTML = text;
		return false;
	} else
	if (email == "") {
		text = "Por favor inserte un correo electrónico"
		error_message.innerHTML = text;
		return false;
	} else
	if (business == "") {
		text = "Indique el nombre de su empresa"
		error_message.innerHTML = text;
		return false;
	}
	if (country == "") {
		text = "Seleccione su país de origen"
		error_message.innerHTML = text;
		return false;
	}
	if (occ == "") {
		text = "Seleccione una ocupación"
		error_message.innerHTML = text;
		return false;
	}
	if (!aviso) {
		text = "Debe estar de acuerdo con nuestro aviso de privacidad"
		error_message.innerHTML = text;
		return false;
	}

}

/**
 * @param String name
 * @return String
 */
 function getParameterByName (name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
  results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

var lang = getParameterByName('lang');
// var page = window.location.pathname.slice(11, -5); // local method
var page = window.location.pathname.slice(1, -5); // production or develop method

$.getJSON('assets/origin/js/lang.json', function(json) {

  if ( lang === 'es' ) {

    $('.lang').each( function(index, value) {
      $(this).text(json[lang][$(this).attr('key')]);
    });

    if ( page ) {
      $('.lang').each( function(index, value) {
        $(this).text(json[lang][page][$(this).attr('key')]);
      });
    }
  } else if ( lang === 'pt' ) {

		$('#renataPT').removeClass('d-none');
		$('#renataES').addClass('d-none');
		$('#alejandraPT').removeClass('d-none');
		$('#alejandraES').addClass('d-none');

    $('.lang').each( function(index, value) {
      $(this).text(json[lang][$(this).attr('key')]);
    });

    if ( page ) {
      $('.lang').each( function(index, value) {
        $(this).text(json[lang][page][$(this).attr('key')]);
      });
    }
  }

}); // cierra json